import React from 'react'
import * as Product from 'modules/product/components/Reference/Properties'
import * as styles from './styles.module.scss'

/**
 *
 */
const Date = () => (
  <div className={styles.Container_Text_Date}>
    <Product.Date />
  </div>
)

/**
 *
 */
const Excerpt = () => (
  <div className={styles.Container_Text_Excerpt}>
    <Product.Excerpt />
  </div>
)

/**
 *
 */
const Title = () => (
  <div className={styles.Container_Text_Title}>
    <Product.Title />
  </div>
)

/**
 *
 */
const Image = () => (
  <div className={styles.Container_ImageContent}>
    <Product.Image className={styles.Container_ImageContent_Image} />
  </div>
)

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Image />
    <div className={styles.Container_Text}>
      <Title />
      <Excerpt />
      <Date />
    </div>
  </Container>
)
