import React from 'react'
import { Context } from './Context'

export const Provider = ({ children, value }) => {
  return (
    <Context.Provider value={{ pageNumber: value }}>
      {children}
    </Context.Provider>
  )
}
