import React from 'react'
import { Content as EntryContent } from './Entry'
import * as styles from './styles.module.scss'
import * as Link from 'components/shared/Links'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Entry = () => (
  <Link.ToProductPage>
    <div className={styles.Entry}>
      <EntryContent />
    </div>
  </Link.ToProductPage>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Entry />
  </Container>
)
