import React from 'react'
import * as Product from 'modules/product/components/Reference/Properties'
import * as Link from 'components/shared/Links'
import * as styles from './styles.module.scss'

/**
 *
 */
const Date = () => (
  <Link.ToProductPage>
    <div className={styles.Container_Text_Date}>
      <Product.Date />
    </div>
  </Link.ToProductPage>
)

/**
 *
 */
const Excerpt = () => (
  <Link.ToProductPage>
    <div className={styles.Container_Text_Excerpt}>
      <Product.Excerpt />
    </div>
  </Link.ToProductPage>
)

/**
 *
 */
const Title = () => (
  <Link.ToProductPage>
    <div className={styles.Container_Text_Title}>
      <Product.Title />
    </div>
  </Link.ToProductPage>
)

/**
 *
 */
const Image = () => (
  <Link.ToProductPage>
    <div className={styles.Container_ImageContent}>
      <Product.Image className={styles.Container_ImageContent_Image} />
    </div>
  </Link.ToProductPage>
)

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Image />
    <div className={styles.Container_Text}>
      <Title />
      <Excerpt />
      <Date />
    </div>
  </Container>
)
