import React from 'react'
import { Content as EntryContent } from './Entry'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Entry = () => (
  <div className={styles.Entry}>
    <EntryContent />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Entry />
  </Container>
)
