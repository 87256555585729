import React from 'react'
import { Content as PagerContent } from 'components/shared/CategoryPager'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Pager = () => (
  <div className={styles.Container_Pager}>
    <PagerContent />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Pager />
  </Container>
)
