import React from 'react'
import * as Category from 'modules/category/components/Reference/Properties/values'
import * as Link from 'components/shared/Links'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Entry = () => {
  return (
    <div className={styles.Entry}>
      <Link.ToEachCategoryLink suffix={'/p/1/'} className={styles.Link}>
        <button type="button" className={styles.Entry_Button}>
          <Category.Name />
        </button>
      </Link.ToEachCategoryLink>
    </div>
  )
}

/**
 *
 */
export const Content = () => (
  <Container>
    <Entry />
  </Container>
)
