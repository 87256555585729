import React from 'react'
import { Context } from './Context'
import { Provider as ReferenceProvider } from './Reference'

/**
 *
 */
export const Each = ({ children }) => {
  const { categories } = React.useContext(Context)
  return categories.map((category) => (
    <ReferenceProvider key={category.id} category={category}>
      {children}
    </ReferenceProvider>
  ))
}
