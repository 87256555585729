import React from 'react'
import { Content as Entry } from './Entries'
import * as Category from 'modules/category/components'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Entries = () => {
  return (
    <div className={styles.Entries}>
      <Category.Each>
        <Entry />
      </Category.Each>
    </div>
  )
}

/**
 *
 */
export const Content = () => (
  <Container>
    <Entries />
  </Container>
)
