import React from 'react'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Title = () => <div className={styles.Title}>カテゴリを選択</div>

/**
 *
 */
export const Content = () => (
  <Container>
    <Title />
  </Container>
)
