import React from 'react'
import { Content as Entry } from './Entries'
import * as Products from 'modules/product/components'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Entries = () => (
  <Products.Each start_offset={5} end_offset={10}>
    <Entry />
  </Products.Each>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Entries />
  </Container>
)
