import React from 'react'
import { Content as Entries } from './Body'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Body = () => {
  return (
    <div>
      <Entries />
    </div>
  )
}

/**
 *
 */
export const Content = () => (
  <Container>
    <Body />
  </Container>
)
