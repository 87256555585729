import React from 'react'
import { Content as FirstPostsContent } from './FirstPosts'
import { Content as LastPostsContent } from './LastPosts'
import { Content as CampaignContent } from './Campaign'
import * as CampaignModule from 'modules/campaign'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const FirstPosts = () => (
  <div>
    <FirstPostsContent />
  </div>
)

/**
 *
 */
const LastPosts = () => (
  <div>
    <LastPostsContent />
  </div>
)

/**
 *
 */
const Campaign = () => (
  <div className={styles.Container_Campaign}>
    <CampaignModule.RamdomPick>
      <CampaignContent />
    </CampaignModule.RamdomPick>
  </div>
)

/**
 *
 */
export const Content = () => {
  return (
    <Container>
      <FirstPosts />
      {/* <Campaign /> */}
      <LastPosts />
    </Container>
  )
}
