import React from 'react'
import { Content as HeaderContent } from './Header'
import { Content as Body } from 'components/shared/CategoryMatchPosts'
import { Content as FooterContent } from './Footer'

import { UpperWavedContainer } from 'components/WavedContainer'

/**
 *
 */
const Header = () => (
  <div>
    <HeaderContent />
  </div>
)

/**
 *
 */
const Footer = () => (
  <div>
    <FooterContent />
  </div>
)

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
export const Content = () => (
  <Container>
    <Header />
    <UpperWavedContainer>
      <Body />
    </UpperWavedContainer>
    <Footer />
  </Container>
)
