import React from 'react'
import * as styles from './styles.module.scss'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { Pagination } from '@material-ui/lab'
import { Context } from 'modules/location/components/Context'
import * as pageCount from '../../../modules/pageNumber/components'

/**
 *
 */

const useStyles = makeStyles((theme) => ({
  ul: {
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#2fb2ce',
    },
  },
}))

export const Content = () => {
  const classes = useStyles()
  const { location } = React.useContext(Context)
  const { pageNumber } = React.useContext(pageCount.Context)
  const categoryPath = location.pathname.slice(0, -1)
  const pageNum = location.pathname.replace(/[^0-9]/g, '')
  const pathPrefix = categoryPath.replace(`p/${pageNum}`, '')
  const locationPath = Number(pageNum)
  const handleChange = (_event, value) => {
    navigate(`${pathPrefix}p/${value}/`)
  }
  return (
    <div>
      <Pagination
        classes={{ ul: classes.ul }}
        variant="outlined"
        defaultPage={locationPath}
        count={pageNumber}
        onChange={handleChange}
      />
    </div>
  )
}
