import React from 'react'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Title = () => (
  <div className={styles.Container_Title}>
    TOPICS
    <div className={styles.Container_Title_Icon} />
  </div>
)

/**
 *
 */
const Description = () => (
  <div className={styles.Container_Description}>
    商品に込められた、知られざる制作秘話をご紹介。
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Title />
    <Description />
  </Container>
)
