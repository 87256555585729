import React from 'react'
import { graphql } from 'gatsby'
import * as Categories from '../modules/category/components'
import * as Products from '../modules/product/components'
import * as Campaign from '../modules/campaign'
import * as Location from '../modules/location/components'
import { Content } from '../partials/CategoriesIndexPage'
import * as PageNumber from '../modules/pageNumber/components'

/**
 *
 */
const CategoryRootTemplate = ({ data, location, pageContext }) => {
  const { categoryNodes = [], numPages, limit, skip, currentPage } = pageContext
  const offsetNum = currentPage * limit

  //GraphQlではカスタムフィールドに入力した値をintにしてくれなかったのでここでintに変換
  const nodes = data.products.nodes.map((e) => {
    const priority = Number(e.acf.priority)

    const newE = { ...e, acf: { priority: priority } }

    return newE
  })

  //カスタムフィールドに入力された数値を比較して降順にデータを並び替える
  const priorityArray = nodes.sort((a, b) => {
    return b.acf.priority - a.acf.priority
  })

  const sortArray = priorityArray.slice(skip, offsetNum)

  return (
    <>
      <Categories.Provider value={{ categories: categoryNodes }}>
        <Products.Provider value={{ products: sortArray }}>
          <Campaign.Provider value={{ campaigns: data.campaigns.nodes }}>
            <Location.Provider value={location}>
              <PageNumber.Provider value={numPages}>
                <Content />
              </PageNumber.Provider>
            </Location.Provider>
          </Campaign.Provider>
        </Products.Provider>
      </Categories.Provider>
    </>
  )
}

/**
 *
 */
export const query = graphql`
  query CategoryRootTemplateQuery($postStatus: String!) {
    products: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { in: "products" } } }
        status: { glob: $postStatus }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        content
        wordpress_id
        date
        excerpt
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, height: 300)
            }
          }
        }
        acf {
          priority
        }
      }
    }
    campaigns: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: "campaign" } } }
        status: { glob: $postStatus }
      }
    ) {
      nodes {
        title
        excerpt
        wordpress_id
        content
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, height: 700)
            }
          }
        }
      }
    }
  }
`

/**
 *
 */
export default CategoryRootTemplate
